.select-role {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.role-text {
  margin: 0 20px;
}

.btn-role {
  cursor: pointer;
  color: #C3B3F1;
}

.btn-role.active {
  text-decoration: underline;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form-container {
  position: relative;
  margin-top: 50px;
  border-radius: 10px;
  width: 700px;
  height: 700px;
  overflow: hidden;
}

@media only screen and (max-width: 699px) {
  .contact-form-container {
    width: 100%;
    padding-top: 125%;
    height: auto;
  }
}

.contact-form {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.contact-form-title {
  font-size: 60px;
}

.contact-row {
  color: #7848FF;
  font-family: 'Space Mono';
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-input {
  width: 85%;
  height: 42px;
  border: solid 2px #7848FF;
  border-radius: 5px;
  font-family: 'Space Mono';
  font-size: 20px;
  color: #7848FF;
  background: #240175;
  margin: 15px 0;
  padding-left: 10px;
}
.contact-input::placeholder {
  color: #7848FF;
  opacity: 1;
}

.contact-textarea {
  height: 126px;
}

.input-error {
  font-family: 'Space Mono';
  font-size: 20px;
  color: red;
  padding-left: 15%
}

.btn {
  display: block;
  width: 352px;
  height: 50px;
  margin: 30px 0;
  font-family: 'Poppins';
  font-size: 16px;
  border-radius: 10px;
}

.btn.submit {
  background-color: #80FFC2;
  color: #17013E;
}

.btn.sign-in {
  background-color: #7848FF;
  color: #FFFFFF;
}
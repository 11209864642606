
.bg-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.bg1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/background.jpg);
  background-position: bottom;
  background-size: cover;
}

.bg2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/bg-pattern.png);
  opacity: 0.4;
}

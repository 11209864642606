.founder-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.founder-rect-top {
  background-color: #67FFB6;
  width: 450px;
  height: 320px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.founder-picture {
  margin-top: -320px;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background-size: contain;
}

.founder-rect-bottom {
  margin-top: 0px;
  background: radial-gradient(52.27% 24.31% at -2.27% 78.32%, #702DFF 0%, rgba(122, 60, 255, 0) 100%), rgba(96, 58, 255, 0.23);
  backdrop-filter: blur(50.66px);
  padding: 50px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-family: 'Space Mono';
  color: #FFFFFF;
}

.founder-name {
  font-size: 32px;
  color: #FFFFFF;
  text-decoration: underline;
}

.founder-title {
  font-size: 17px;
  color: #67FFB6;
}

.founder-desc {
  margin-top: 30px;
  font-family: 'Poppins';
  font-size: 15px;
}
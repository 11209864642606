.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.main-content {
  box-sizing: border-box;
  max-width: 1200px;
  width: 100%;
  padding: 40px;
}

h1 {
  margin-top: 30px;
  font-family: 'Space Mono';
  font-size: 78px;
  color: #80FFC2;
  line-height: 100%;
}

@media only screen and (max-width: 779px) {
  h1 {
    font-size: 56px;
  }
}

@media only screen and (max-width: 379px) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  font-family: 'Space Mono';
  font-size: 23px;
  color: #7848FF;
}

p, li {
  font-family: 'Poppins';
  font-size: 16px;
  color: #C9BFD8;
}

p a {
  color: #C9BFD8;
}

@font-face {
  font-family: "poppins";
  src: local("Poppins Medium"),
    url("./assets/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "space mono";
  src: local("Space Mono Bold"),
    url("./assets/fonts/spacemono/SpaceMono-Bold.ttf");
}

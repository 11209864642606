
.img-logo-notext {
  display: none;
  width: 70px;
}

.img-logo-text {
  display: unset;
}

.header-container {
  padding-top: 52px;
  width: calc(100% - 30px);
  max-width: 1200px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-link {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18.9379px;
  line-height: 28px;
  color: #6F6689;
  text-decoration: none;
  margin-left: 24px;
}
.header-link:first-of-type {
  margin-left: 0;
}

.header-link.active {
  color: #C3B3F1;
  border-bottom: 1px solid #C3B3F1;
}

.social-links {
  display: flex;
}

.social-link {
  width: 40px;
  height: 48px;
  background-color: #3D2389;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.social-link:first-of-type {
  margin-left: 0;
}

@media only screen and (max-width: 779px) {
  .img-logo-text {
    display: none;
  }
  .img-logo-notext {
    display: unset;
  }
  .header-container {
    align-items: flex-start;
    padding-top: 20px;
    justify-content: space-between;
    margin-right: 0;
    margin-left: 30px;
  }
  .header-links {
    padding-right: 30px;
    display: flex;
    flex-direction: column;
  }
  .header-link {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .social-links {
    flex-direction: column;
  }
  .social-link:first-of-type {
    margin-left: 0;
  }
  .social-link {
    margin-left: 0;
    margin-bottom: 10px;
  }
}


.footer {
  font-family: 'Poppins';
  font-size: 16px;
  color: #C9BFD8;
  padding: 50px;
}

.footer-links {
  display: flex;
  justify-content: center;
}

.footer-link {
  margin: 0 10px;
  color: #C9BFD8;
}
